// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-about-template-js": () => import("./../../../src/templates/about-template.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/contact-template.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-tags-template-js": () => import("./../../../src/templates/tags-template.js" /* webpackChunkName: "component---src-templates-tags-template-js" */)
}

